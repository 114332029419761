import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Introduction to what this document is`}</em></p>
    <h2>{`Designing Styles`}</h2>
    <p>{`Evaluate existing styles.`}</p>
    <p>{`Create a clear purpose and use case for a new style.`}</p>
    <p>{`Understanding the brand.`}</p>
    <h2>{`Designing Components`}</h2>
    <p>{`Evaluate existing components.`}</p>
    <p>{`Important to establish a clear use case and purpose of a component.`}</p>
    <p>{`Understand use cases, layout structure, edge cases, states.`}</p>
    <h3>{`Creating Specifications`}</h3>
    <h2>{`Designing Patterns`}</h2>
    <p>{`Evaluate existing patterns.`}</p>
    <p>{`Create a clear purpose and use case for a new pattern.`}</p>
    <p>{`Explore use cases within different product suites.`}</p>
    <p>{`Identify use case based on characteristics of user:`}</p>
    <ol>
      <li parentName="ol">{`User is familiar with the application or new to it?`}</li>
      <li parentName="ol">{`User is familiar with the use case or new to it?`}</li>
      <li parentName="ol">{`User is in a rush or has time to move carefully?`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      